<template>
  <div class="card">
    <div class="card-header">
      <div class="utils__title">
        <strong>Zprávy - Upravit <span class="utils__link--underlined text-primary">#{{ this.id }}</span></strong>
      </div>
      <action-tools :router-options="routerOptions"></action-tools>
    </div>
    <div class="card-body">
      <a-form :form="form" @submit="handleSubmit">
        <a-form-item
          :validate-status="error('name') ? 'error' : ''"
          :help="error('name') || ''"
          label="Název"
        >
          <a-input placeholder="Název" v-decorator="['name', {rules: [{max: 40, message: 'Název nemůže být delší než 40 znaků!'}, {required: true, message: 'Název musí být vyplněn!'}]}]"/>
        </a-form-item>

        <a-form-item
          :validate-status="error('message') ? 'error' : ''"
          :help="error('message') || ''"
          label="Zpráva"
        >
          <a-input placeholder="Zpráva" v-decorator="['message', {rules: [{required: true, message: 'Zpráva musí být vyplněna!'}]}]"/>
        </a-form-item>

        <a-form-item
          label="Barva pozadí"
        >
          <a-radio-group name="radioGroup" v-decorator="['type', { initialValue: this.types[0].type, rules: [{ required: true, message: 'Barva pozadí musí být vyplněna!' }] }]">
            <a-radio :style="radioStyle" :key="index" :value="type.type" v-for="(type, index) in types">{{ type.name }}</a-radio>
          </a-radio-group>
        </a-form-item>

        <a-form-item label="Zveřejnit?">
          <a-checkbox v-decorator="['is_active', { valuePropName: 'checked', initialValue: true }]"/>
        </a-form-item>
        <div class="form-actions">
          <a-button html-type="submit" type="primary" :disabled="loading || hasErrors(form.getFieldsError())">Přidat</a-button>
        </div>
      </a-form>
    </div>
  </div>
</template>

<script>
import ActionTools from '@/components/MyComponents/ActionTools'
import { hasErrors } from '@/services/forms'

export default {
  components: { ActionTools },
  beforeCreate() {
    this.form = this.$form.createForm(this)
    this.form.getFieldDecorator('name')
    this.form.getFieldDecorator('message')
    this.form.getFieldDecorator('type')
    this.form.getFieldDecorator('is_active')
  },
  data: function () {
    return {
      hasErrors,
      radioStyle: {
        display: 'block',
        height: '30px',
        lineHeight: '30px',
      },
      id: this.$route.params.id,
      loading: false,
      routerOptions: [
        {
          icon: 'bars',
          theme: 'outlined',
          to: '/messages',
          title: 'Seznam',
        },
      ],
    }
  },
  computed: {
    detail: function() {
      return this.$store.getters['messages/getDetail']
    },
    types: function() {
      return this.$store.getters['messages/getTypes']
    },
  },
  methods: {
    initDetail() {
      this.loading = true
      this.$store.dispatch('messages/getOne', this.id)
        .then(() => {
          this.form.setFieldsValue({
            name: this.detail.name,
            message: this.detail.message,
            type: this.detail.type,
            is_active: this.detail.is_active,
          })
        })
        .catch(() => {})
        .finally(() => {
          this.loading = false
        })
    },
    getData() {
      return {
        name: this.form.getFieldValue('name'),
        message: this.form.getFieldValue('message'),
        type: this.form.getFieldValue('type'),
        is_active: this.form.getFieldValue('is_active'),
      }
    },
    handleSubmit(e) {
      e.preventDefault()
      this.form.validateFields((err, values) => {
        if (!err) {
          this.loading = true
          this.$store.dispatch('messages/put', { id: this.id, item: this.getData() })
            .catch(() => {})
            .finally(() => {
              this.loading = false
            })
        }
      })
    },
    error(fieldName) {
      const { getFieldError, isFieldTouched } = this.form
      return isFieldTouched(fieldName) && getFieldError(fieldName)
    },
    refreshComponent(id) {
      this.id = id
      this.loading = false
    },
  },
  created() {
    this.initDetail()
  },
  watch: {
    '$route.params.id'(newId, oldId) {
      this.refreshComponent(newId)
      this.initDetail()
    },
  },
}
</script>
<style lang="scss" module>
</style>
